/**
 * @file Handle stuff related to keyboard input events
 * @see https://clark.engineering/input-on-android-229-unidentified-1d92105b9a04
 */

// More available values here: https://rawgit.com/w3c/input-events/v1/index.html#interface-InputEvent-Attributes
// Only add what we need now
type InputType =
  | 'insertText'
  | 'insertCompositionText'
  | 'insertReplacementText'
  | 'insertFromPaste'
  | 'insertFromPasteAsQuotation'
  | 'insertFromDrop'
  | 'insertLink'
  | 'deleteContentBackward'
  | 'deleteContentForward'
  | 'deleteWordBackward'
  | 'deleteWordForward'
  | 'deleteSoftLineBackward'
  | 'deleteByCut'
  | 'deleteByDrag'

interface CompatibleInputEvent {
  /**
   * Inserted characters
   */
  data?: string
  /**
   * Whether the inserted characters are part of a composition
   */
  isComposing?: boolean
  /**
   * Type of change made to the text
   * @see https://rawgit.com/w3c/input-events/v1/index.html#interface-InputEvent-Attributes
   */
  inputType?: InputType
  /**
   * Type of navigation made in the text
   */
  navigationType?: 'cursorLeft' | 'cursorRight'
  /**
   * Original event
   */
  originalEvent: KeyboardEvent | InputEvent
}

const isPrintableCharacter = (key: string): boolean => {
  // Printable characters have a length of 1 and are not control characters.
  // The following regex matches code points from U+0000 (null) to U+001F (unit separator)
  // and U+007F (delete). They are considered control characters.
  // eslint-disable-next-line no-control-regex
  return key.length === 1 && !/^[\x00-\x1F\x7F]$/.test(key)
}

const normalizeInputEvent = (event: KeyboardEvent | InputEvent): CompatibleInputEvent => {
  const e: CompatibleInputEvent = {
    originalEvent: event,
  }

  if (event instanceof KeyboardEvent) {
    if (event.key === 'Backspace') {
      e.inputType = event.metaKey
        ? 'deleteSoftLineBackward'
        : event.altKey
        ? 'deleteWordBackward'
        : 'deleteContentBackward'
      e.navigationType = 'cursorLeft'
    } else if (event.key === 'Delete') {
      e.inputType = 'deleteContentForward'
    } else if (event.key.startsWith('Arrow')) {
      e.navigationType = event.key.replace('Arrow', 'cursor') as CompatibleInputEvent['navigationType']
    } else if (event.key === 'Home') {
      e.navigationType = 'cursorLeft'
    } else if (event.key === 'End') {
      e.navigationType = 'cursorRight'
    } else {
      e.data = event.key
      e.isComposing = event.isComposing ?? false
      e.inputType = isPrintableCharacter(event.key) ? 'insertText' : undefined
    }
  } else {
    const { inputType } = event
    e.inputType = inputType as InputType
    e.data = event.data ?? undefined
    e.isComposing = event.isComposing ?? false

    if (inputType === 'insertText') {
      e.navigationType = 'cursorRight'
    }
  }

  return e
}

export type { CompatibleInputEvent }
export { isPrintableCharacter, normalizeInputEvent }
