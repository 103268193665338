// @file Helper class to work with mentions
import type { Id, UserMentionSuggestion, UserWithHashId } from '@@/types'

/**
 * This constant should be synchronized with
 * `DEFAULT_PAGE_SIZE` in `Api::MentionSuggestionsController`.
 */
const MENTION_SUGGESTIONS_LIMIT = 5

// This is a subset of `MentionEvent` in `vuedirectives/mention_autocomplete.ts`
// for backward compatibility.
interface TiptapMentionEvent {
  postId?: Id
  caretCoordinates?: { top: number; left: number }
  mentionRange?: [number, number]
  searchTerm?: string
  insertMentionFunction?: InsertMentionFunction
}

type InsertMentionFunction = ({
  suggestion,
  mentioner,
}: {
  suggestion: UserMentionSuggestion
  mentioner: Pick<UserWithHashId, 'hashid'>
}) => UserMentionSuggestion | undefined

class SelectedMentionSuggestionKeyboardEvent extends KeyboardEvent {
  selectedSuggestion: UserMentionSuggestion

  constructor(type: string, eventInitDict: KeyboardEventInit, selectedSuggestion: UserMentionSuggestion) {
    super(type, eventInitDict)
    this.selectedSuggestion = selectedSuggestion
  }
}

export { MENTION_SUGGESTIONS_LIMIT, SelectedMentionSuggestionKeyboardEvent }
export type { InsertMentionFunction, TiptapMentionEvent }
